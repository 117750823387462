<template>
  <div class="Polaris-FormLayout__Item">
    <label class="Polaris-Choice m-0" :for="id">
      <span class="Polaris-Choice__Control"><span class="Polaris-Checkbox">
          <input :id="id" type="checkbox" class="Polaris-Checkbox__Input" aria-invalid="false" 
            @click="$emit('input', $event.target.checked)" role="checkbox" aria-checked="false">
          <span class="Polaris-Checkbox__Backdrop">
          </span>
          <span class="Polaris-Checkbox__Icon">
            <span class="Polaris-Icon">
              <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                <path
                  d="M8.315 13.859l-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.437.437 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0">
                </path>
              </svg>
            </span>
          </span>
        </span>
      </span>
      <span class="Polaris-Choice__Label" v-html="label"></span>
    </label>
    <div class="Polaris-Labelled__Error m-0" v-if="invalid">
      <div class="Polaris-InlineError">
        <div class="Polaris-InlineError__Icon">
          <span class="Polaris-Icon">
            <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
              <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z" fill-rule="evenodd"></path>
            </svg>
          </span>
        </div>
        {{ invalid }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
      },
      label: {
        type: String,
      },
      invalid: {
        type: String,
        default: null,
      },
    },
    computed: {
      id() {
        return this._uid;
      }
    },
    methods: {
    }
  }
</script>

<style>

</style>