<template>
  <div class="Polaris-Page">
    <div class="Polaris-Page__Content">
      <div class="Polaris-Card">
        <div class="Polaris-Card__Header py-4 d-flex justify-content-center flex-column align-items-center ">
          <img src="@/assets/images/logo-ecart.svg" alt="" class=" img-fluid img-100 my-4">
          <h1 class="Polaris-DisplayText Polaris-DisplayText--sizeMedium my-4 ">Welcome to Ecart integration</h1>
        </div>
      </div>
      <div class="Polaris-Card">
        <div class="Polaris-Card__Section">
          <b-tabs content-class="pt-3 border-left border-bottom border-right " fill>
            <b-tab title="Create new account" :active="!showLogin">
              <div class="Polaris-Card animated fadeIn shadow-none ">
                <div class="Polaris-Card__Header">
                  <h3> <strong>Create account.</strong> </h3>
                </div>
                <div class="Polaris-Card__Section">
                  <div class="Polaris-FormLayout">
                    <div class="Polaris-FormLayout--grouped">
                      <v-observer tag="form" ref="formSignup" @submit.prevent="fnValidateNewAccount()">
                        <v-validation rules="required" v-slot="{ errors }" :name=" 'Country' ">
                          <PolarisSelect v-model="tempUserData.country" :invalid=" errors[0] " :label=" 'Country' "
                            :options=" countryListShopify " />
                        </v-validation>

                        <v-validation rules="required|email" v-slot="{ errors }" :name=" 'Email' ">
                          <PolarisInputText label='Email' v-model="tempUserData.email" :invalid=" errors[0] " />
                        </v-validation>

                        <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'Name' ">
                          <PolarisInputText label='Name' v-model="tempUserData.name" :invalid=" errors[0] "
                            type="text" />
                        </v-validation>
                        <v-validation rules="required|min:3" v-slot="{ errors }" :name=" 'Last name' ">
                          <PolarisInputText label='Last name' v-model="tempUserData.lastName" :invalid=" errors[0] "
                            type="text" />
                        </v-validation>

                        <v-validation rules="required|min:10|max:15" v-slot="{ errors }" :name=" 'Phone' ">
                          <PolarisInputText label='Phone' v-model="tempUserData.phone" :invalid=" errors[0] "
                            type="number" />
                        </v-validation>
                        
                        <v-validation rules="required|min:8" v-slot="{ errors }" :name=" 'Password' ">
                          <PolarisInputText label='Password' v-model="tempUserData.password1" :invalid=" errors[0] "
                            type="password" />
                        </v-validation>
                        <v-validation rules="required|min:8" v-slot="{ errors }" :name=" 'Repeat password' ">
                          <PolarisInputText label='Repeat password' v-model="tempUserData.password2"
                            :invalid=" errors[0] " type="password" />
                        </v-validation>
                        <div class="Polaris-FormLayout__Item text-danger mt-4 "
                          v-show=" (tempUserData.password1 != null && tempUserData.password2 != null) && ( tempUserData.password1 != tempUserData.password2 ) ">
                          <p v-text=" 'Passwords do not match' "></p>
                        </div>
                        <v-validation :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
                          :name=" 'Terms and conditions' ">
                          <PolarisCheckbox v-model="tempUserData.terms" :invalid=" errors[0] "
                            label='I accept the <a href="/terms-&-conditions" target="_blank">terms and conditions</a> ' />
                        </v-validation>
                      </v-observer>
                    </div>
                  </div>
                  <div class="Polaris-SettingAction d-flex align-items-end justify-content-end">
                    <div class="Polaris-SettingAction__Action">
                      <button type="button" class="Polaris-Button Polaris-Button--primary" :disabled="sendingData"
                        @click="fnValidateNewAccount()">
                        <span class="Polaris-Button__Content"><span class="Polaris-Button__Text">Create</span>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </b-tab>
            <b-tab title="Login" :active="showLogin">
              <div class="Polaris-Card animated fadeIn shadow-none">
                <div class="Polaris-Card__Header">
                  <h3> <strong>Login.</strong> </h3>
                </div>
                <div class="Polaris-Card__Section">
                  <div class="Polaris-FormLayout">
                    <div class="Polaris-FormLayout--grouped">
                      <v-observer tag="form" ref="formLogin" @submit.prevent="fnValidateLogin()">
                        <v-validation rules="required|email" v-slot="{ errors }" :name="'Email'">
                          <PolarisInputText label='Email' v-model="tempUserData.login.email" :invalid=" errors[0] " />
                        </v-validation>

                        <v-validation rules="required" v-slot="{ errors }" :name=" 'Password' ">
                          <PolarisInputText label='Password' v-model="tempUserData.login.password"
                            :invalid=" errors[0] " type="password" />
                        </v-validation>
                      </v-observer>
                    </div>
                  </div>
                  <div class="Polaris-SettingAction d-flex align-items-end justify-content-end my-4">
                    <div class="Polaris-SettingAction__Action">
                      <button type="button" class="Polaris-Button Polaris-Button--primary " @click="fnValidateLogin()"
                        :class="loading.data ? 'Polaris-Button--disabled Polaris-Button--loading':'' "
                        :disabled="loading.data">
                        <span class="Polaris-Button__Content">
                          <span class="Polaris-Button__Spinner" v-show="loading.data ">
                            <span
                              class="Polaris-Spinner Polaris-Spinner--colorInkLightest Polaris-Spinner--sizeSmall"><svg
                                viewBox="0 0 20 20">
                                <path
                                  d="M7.229 1.173a9.25 9.25 0 1011.655 11.412 1.25 1.25 0 10-2.4-.698 6.75 6.75 0 11-8.506-8.329 1.25 1.25 0 10-.75-2.385z">
                                </path>
                              </svg>
                            </span>
                            <span role="status">
                              <span class="Polaris-VisuallyHidden" v-text=" 'Login' "></span>
                            </span>
                          </span>
                          <span class="Polaris-Button__Text" v-text=" 'Login' ">
                          </span>
                        </span>
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import '@shopify/polaris/styles.css';
  import PolarisInputText from '@/components/shopify/polaris-input-text';
  import PolarisCheckbox from '@/components/shopify/polaris-checkbox';
  import PolarisSelect from '@/components/shopify/polaris-select';

  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  export default {
    components: {
      PolarisSelect,
      PolarisInputText,
      PolarisCheckbox,
    },
    data() {
      return {
        showLogin: false,
        showAlert: false,
        tempUserData: {
          login: {
            email: null,
            password1: null,
          },
          country: null,
          email: null,
          name: null,
          lastName: null,
          phone: null,
          password1: null,
          password2: null,
          terms: false,
        },
        sendingData: false,
        countryListShopify: [],
        countryList: [],
      }
    },
    computed: {
      ...mapState('ShopifySession', ['userShopify', 'loading']),
    },
    methods: {
      ...mapMutations('menu', ['setLoader']),
      ...mapActions('ShopifySession', ['fnApiShopifyLogin','fnApiShopifySignup']),
      async fnValidateNewAccount() {
        if (await this.$refs['formSignup'].validate() && (this.tempUserData.password1 == this.tempUserData.password2)) {
            let phoneCode = this.countryList.find(country => country.code == this.tempUserData.country).phone_code;
            this.fnApiShopifySignup({
            email: this.tempUserData.email,
            country: this.tempUserData.country,
            code: phoneCode,
            name: this.tempUserData.name,
            lastName: this.tempUserData.lastName,
            phone: this.tempUserData.phone,
            password: this.tempUserData.password1,
            storeId: this.$route.query.store_id,
            redirectTo: this.$route.query.referrer ? window.atob(this.$route.query.referrer) : '/shopify/account',
          });
        }
      },

      async fnValidateLogin() {
        if (await this.$refs['formLogin'].validate()) {
          this.fnApiShopifyLogin({
            email: this.tempUserData.login.email,
            password: this.tempUserData.login.password,
            storeId: this.$route.query.store_id,
            redirectTo: this.$route.query.referrer ? window.atob(this.$route.query.referrer) : '/shopify/account',
          });
        }
      },
    },

    async mounted() {
      this.countryList = await this.fnApiGetCountries();
      for (let country of this.countryList) {
        this.countryListShopify.push({
          label: country.name,
          value: country.code,
        })
      }
      if (this.$route.query.seller_id) {
        this.showLogin = true;
      }
    }

  }
</script>