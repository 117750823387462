<template>
    <div>
        <div class="Polaris-FormLayout__Item">
            <div class="Polaris-Labelled__LabelWrapper">
                <div class="Polaris-Label">
                    <label :id="id+'label'" :for="id" class="Polaris-Label__Text" v-text="label"> </label>
                </div>
            </div>
            <div class="Polaris-Select"
                :class=" { 'Polaris-Select--disabled':disabled ,  'Polaris-Select--error' :invalid  } ">
                <select :id="id" class="Polaris-Select__Input" aria-invalid="false" :value="value" :disabled="disabled"
                    @change=" $emit('input', $event.target.value) ; $emit('change', $event.target.value) ">
                    <option :value="null" selected v-text=" 'Select' ">
                    </option>
                    <option :value="option.value" v-for=" (option, index) in options " :key="index"
                        v-text="option.label"></option>
                </select>
                <div class="Polaris-Select__Content" aria-hidden="true">
                    <span class="Polaris-Select__SelectedOption" v-text="selectedOption"></span>
                    <span class="Polaris-Select__Icon"><span class="Polaris-Icon">
                            <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                                <path d="M13 8l-3-3-3 3h6zm-.1 4L10 14.9 7.1 12h5.8z" fill-rule="evenodd"></path>
                            </svg>
                        </span>
                    </span>
                </div>
                <div class="Polaris-Select__Backdrop"></div>
            </div>
            <div class="Polaris-Labelled__Error" v-if="invalid">
                <div class="Polaris-InlineError">
                    <div class="Polaris-InlineError__Icon">
                        <span class="Polaris-Icon">
                            <svg viewBox="0 0 20 20" class="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                                <path d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16zm-1-8h2V6H9v4zm0 4h2v-2H9v2z"
                                    fill-rule="evenodd"></path>
                            </svg>
                        </span>
                    </div>
                    {{ invalid }}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
            },
            label: {
                type: String,
            },
            helpText: {
                type: String,
            },
            invalid: {
                type: String,
                default: null,
            },
            type: {
                type: String,
                default: "text"
            },
            options: {
                type: Array,
                required: true,
            },
            disabled: {
                type: Boolean,
            }
        },
        computed: {
            id() {
                return this._uid;
            },
            selectedOption() {
                let tempOption = this.options.find(option => option.value == this.value);
                if (tempOption) {
                    return tempOption.label;
                }
                return 'Select';
            },
        }
    }
</script>

<style>

</style>